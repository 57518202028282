<template>
  <div>
    <v-data-table
      ref="dataTable"
      :loading="isLoading || isLoadingVendingHistories"
      :headers="VendingHistoryListHeaders"
      :items="vendingHistories"
      :header-props="{ sortByText: 'ソート' }"
      :footer-props="{ itemsPerPageText: '行/ページ:' }"
      no-data-text="データがありません"
      :page="$route.query.page ? Number($route.query.page) : 1"
      @update:page="($event) => {
        $vuetify.goTo($refs.dataTable)
        addQuery({ page: $event })
      }"
    >
      <template #loading>
        <v-skeleton-loader type="table" />
      </template>

      <template #top>
        <div class="w-full d-flex align-center">
          <v-menu
            v-model="isFilterMenuOpen"
            :close-on-content-click="false"
            min-width="300"
            offset-y
          >
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                class="ml-2"
              >
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiFilterMenuOutline }}
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-subheader>商品</v-subheader>
              <v-list-item>
                <v-list-item-content class="px-2">
                  <vendible-select
                    :vendibles="vendibles"
                    class="pa-0 ma-0"
                    @selected="getVendingHistories({ ...$event, ...$route.query })"
                    @cleared="getVendingHistories($route.query)"
                  />
                </v-list-item-content>
              </v-list-item>

              <v-subheader>受領者</v-subheader>
              <v-list-item>
                <v-list-item-content class="px-2">
                  <user-select
                    :users="recipients"
                    :user-id-key-name="'recipientId'"
                    :role-order="['cast', 'waiter']"
                    class="pa-0 ma-0"
                    @selected="getVendingHistories({ ...$event, ...$route.query })"
                    @cleared="getVendingHistories($route.query)"
                  />
                </v-list-item-content>
              </v-list-item>

              <v-subheader>売上計上先</v-subheader>
              <v-list-item>
                <v-list-item-content class="px-2">
                  <user-select
                    :users="users"
                    :user-id-key-name="'contributorId'"
                    :role-order="['alliance', 'cast', 'waiter']"
                    class="pa-0 ma-0"
                    :prepend-inner-icon="icons.mdiCashMarker"
                    @selected="getVendingHistories({ ...$event, ...$route.query })"
                    @cleared="getVendingHistories($route.query)"
                  />
                </v-list-item-content>
              </v-list-item>
              <v-subheader />
            </v-list>
          </v-menu>
          <v-spacer />
          <v-btn
            :loading="isLoading"
            :disabled="vendingHistories.length <= 0 || isLoading"
            :ripple="false"
            color="secondary"
            small
            class="ma-2"
            @click="download"
          >
            <v-icon left>
              {{ icons.mdiFileExcel }}
            </v-icon>
            ダウンロード
          </v-btn>
        </div>
      </template>

      <template #[`item.attributes.vendibleName`]="{ item }">
        {{ item.attributes.vendibleName }}
      </template>

      <template #[`item.attributes.sellingPrice`]="{ item }">
        {{ `¥${item.attributes.sellingPrice.toLocaleString()}` }}
      </template>

      <template #[`item.attributes.taxPrice`]="{ item }">
        {{ `¥${item.attributes.taxPrice.toLocaleString()}` }}
        <sup
          v-if="item.attributes.taxCharge === 'included'"
          class="primary--text"
        >
          込み
        </sup>
        <sup
          v-if="item.attributes.taxCharge === 'cut'"
          class="primary--text"
        >
          カット
        </sup>
      </template>

      <template #[`item.recipients`]="{ item }">
        <v-chip-group
          v-if="item.attributes.vendibleRecipients.data.length > 0"
          column
          small-chips
        >
          <v-chip
            v-for="vendibleRecipient in item.attributes.vendibleRecipients.data"
            :key="`vendibleRecipient${vendibleRecipient.id}`"
            small
            outlined
            style="pointer-events: none;"
          >
            {{ vendibleRecipient.attributes.recipient.data.attributes.name }}

            <template v-if="vendibleRecipient.attributes.ratio !== 1.0">
              (
              <span
                class="text-truncate"
                style="max-width: 36px"
              >
                {{ vendibleRecipient.attributes.ratio * 100 }}
              </span>
              %)
            </template>
          </v-chip>
        </v-chip-group>
      </template>

      <template #[`item.contributors`]="{ item }">
        <v-chip-group
          v-if="item.attributes.vendingContributions.data.length > 0"
          column
          small-chips
        >
          <v-chip
            v-for="vendingContribution in item.attributes.vendingContributions.data"
            :key="`vendingContribution${vendingContribution.id}`"
            small
            outlined
            style="pointer-events: none;"
          >
            {{ vendingContribution.attributes.contributor.data.attributes.name }}

            <template v-if="vendingContribution.attributes.ratio !== 1.0">
              (
              <span
                class="text-truncate"
                style="max-width: 36px"
              >
                {{ vendingContribution.attributes.ratio * 100 }}
              </span>
              %)
            </template>
          </v-chip>
        </v-chip-group>
      </template>

      <template #[`item.attributes.createdAt`]="{item}">
        <span class="text-no-wrap">{{ dateTime(item.attributes.createdAt) }}</span>
      </template>

      <template #[`item.meta.receiptId`]="{ item }">
        <v-chip
          v-if="item.meta && item.meta.receiptId"
          small
          @click="$emit('open-receipt', item.meta.receiptId)"
        >
          <clip-loader
            v-if="isLoadingReceipt && +receipt.id === +item.meta.receiptId"
            :loading="true"
            :color="'#8A8D93'"
            size="16px"
            class="d-flex align-items pr-2"
          />
          伝票 No.{{ item.meta.receiptId }}
        </v-chip>
        <v-chip
          v-else
          color="success"
          class="v-chip-light-bg success--text"
          small
        >
          進行中の卓
        </v-chip>
      </template>

      <template #[`footer.page-text`]="props">
        {{ props.pageStart }} - {{ props.pageStop }} / {{ props.itemsLength }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {
  ref,
  computed,
  inject,
} from '@vue/composition-api'
import {
  mdiCalendar,
  mdiNoteSearchOutline,
  mdiCashMarker,
  mdiFileExcel,
  mdiFilterMenuOutline,
} from '@mdi/js'
import { reject } from 'lodash'
import * as XLSX from 'xlsx/xlsx.mjs'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import VendingHistoryApi from '@/api/waiter/VendingHistory'
import useDateFormat from '@/views/composable/useDateFormat'
import useQueryString from '@/views/composable/useQueryString'
import useCurrentData from '@/views/composable/useCurrentData'
import useCompInit from '@/views/composable/useCompInit'
import useVendingHistoriesDataTable from '@/views/composable/data-table/useVendingHistoriesOldDataTable'
import VendibleSelect from '@/views/components/util/filter/VendibleSelect.vue'
import UserSelect from '@/views/components/util/filter/UserSelect.vue'
import { VendingHistoryListHeaders } from '@/utils/constants/table-headers'

export default {
  components: {
    VendibleSelect,
    UserSelect,
    ClipLoader,
  },
  props: {
    date: {
      type: String,
      required: true,
      default: () => new Date().toISOString().substr(0, 10),
    },
    interval: {
      type: Number,
      required: true,
      default: 0,
    },
    endDate: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const isLoadingVendingHistories = ref(false)
    const vendingHistories = ref([])
    const isDownloading = ref(false)
    const vendibles = inject('vendibles', [])
    const users = inject('users', [])
    const receipt = inject('receipt', {})
    const isLoadingReceipt = inject('isLoadingReceipt', false)
    const isReceiptOpen = inject('isReceiptOpen', false)
    const isFilterMenuOpen = ref(false)

    const { dateTime } = useDateFormat()
    const { route, addQuery } = useQueryString()
    const { currentClub } = useCurrentData()
    const { isLoading, initWith } = useCompInit()
    const { buildXlsxData } = useVendingHistoriesDataTable(vendingHistories)

    const recipients = computed(() => {
      return reject(users.value, user => user.attributes.role === 'alliance')
    })

    const getVendingHistories = async ({
      date = null,
      interval = null,
      checkedOut = null,
      beforeCheckOut = null,
      vendibleId = null,
      vendibleType = null,
      recipientId = null,
      tableId = null,
      isNonExistVendible = null,
      contributorId = null,
      endDate = null,
    }) => {
      isLoadingVendingHistories.value = true

      const res = await VendingHistoryApi.getVendingHistories({
        date,
        interval,
        checkedOut,
        beforeCheckOut,
        vendibleId,
        vendibleType,
        recipientId,
        tableId,
        isNonExistVendible,
        contributorId,
        endDate,
      })

      if (res?.data?.data) {
        vendingHistories.value = res.data.data
      }

      isLoadingVendingHistories.value = false
    }

    const btnTo = item => {
      const to = { name: '', params: {}, query: {} }
      to.params.club_slugs = currentClub.value?.slug

      if (item.meta.receiptId) {
        to.name = 'waiter-data-receipts'
        to.query.id = item.meta.receiptId
      } else {
        to.name = 'waiter-halls'
        to.query.tableId = item.attributes.tableId
      }
      to.query.vendingHistoryId = item.id

      return to
    }

    const download = () => {
      isDownloading.value = true

      const vendingHistoriesExcel = buildXlsxData()

      const wb = XLSX.utils.book_new()
      const data = XLSX.utils.json_to_sheet(vendingHistoriesExcel)
      XLSX.utils.book_append_sheet(wb, data, '販売履歴')

      let title = `${props.date}_${['日', '週', '月'][props.interval]}`
      if (props.endDate) title = `${props.date}~${props.endDate}`
      XLSX.writeFile(wb, `販売履歴_${title}.xlsx`)

      isDownloading.value = false
    }

    initWith([
      getVendingHistories(route.value.query),
    ])

    return {
      // data
      isLoading,
      isLoadingVendingHistories,
      vendingHistories,
      vendibles,
      users,
      receipt,
      isLoadingReceipt,
      isReceiptOpen,
      isFilterMenuOpen,
      VendingHistoryListHeaders,

      // computed
      recipients,

      // methods
      dateTime,
      addQuery,
      getVendingHistories,
      btnTo,
      download,

      icons: {
        mdiCalendar,
        mdiNoteSearchOutline,
        mdiCashMarker,
        mdiFileExcel,
        mdiFilterMenuOutline,
      },
    }
  },
}
</script>
