var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{ref:"dataTable",staticClass:"cursor-pointer",attrs:{"loading":_vm.isLoading,"headers":_vm.BalanceReceivableHeaders,"items":_vm.balanceReceivables,"header-props":{ sortByText: 'ソート' },"footer-props":{ itemsPerPageText: '行/ページ:' },"no-data-text":"データがありません","page":_vm.$route.query.page ? Number(_vm.$route.query.page) : 1},on:{"update:page":function ($event) {
      _vm.$vuetify.goTo(_vm.$refs.dataTable)
      _vm.addQuery({ page: $event })
    }},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"table"}})]},proxy:true},{key:"top",fn:function(){return [_c('div',{staticClass:"w-full d-flex align-center"},[_c('switcher',{staticClass:"pa-0 ma-0 ml-2",attrs:{"bool-key-name":"isPaid","label":"回収済","color":"success"},on:{"switched":function($event){return _vm.getBalanceReceivables(Object.assign({}, $event, _vm.$route.query))}}}),_c('v-spacer'),_c('v-btn',{staticClass:"ma-2",attrs:{"loading":_vm.isLoading,"disabled":_vm.balanceReceivables.length <= 0 || _vm.isLoading,"ripple":false,"color":"secondary","small":""},on:{"click":_vm.download}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileExcel)+" ")]),_vm._v(" ダウンロード ")],1)],1)]},proxy:true},{key:"item.meta.paid",fn:function(ref){
    var item = ref.item;
return [_c('v-avatar',{attrs:{"color":item.attributes.amount <= item.meta.paidAmount ? 'success' : 'error',"size":"16"}})]}},{key:"item.attributes.amount",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(("¥" + (item.attributes.amount.toLocaleString())))+" ")]}},{key:"item.meta.paidAmount",fn:function(ref){
    var item = ref.item;
return [_c('yona-edit-dialog',{key:JSON.stringify(item),attrs:{"display-name":("¥" + (item.meta.paidAmount.toLocaleString()))},on:{"open":function($event){_vm.edit({ cashRegisterTransactionsAttributes: _vm.initCashRegisterTransactionsTemp(item.attributes.cashRegisterTransactions.data || []) })},"close":_vm.cancelEdit,"save":function($event){return _vm.update(item.id)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_vm._l((_vm.cashRegisterTransactionsTemp),function(cashRegisterTransactionTemp,cashRegisterTransactionTempIdx){return _c('v-card',{key:("cashRegisterTransactionsTemp[" + cashRegisterTransactionTempIdx + "]"),staticClass:"mb-4",class:cashRegisterTransactionTemp._destroy ? '_destroy-color' : ''},[_c('v-btn',{staticClass:"remove-btn",attrs:{"icon":"","fab":"","ripple":false},on:{"click":function($event){cashRegisterTransactionTemp.id
                ? cashRegisterTransactionTemp._destroy = !cashRegisterTransactionTemp._destroy
                : _vm.cashRegisterTransactionsTemp.splice(cashRegisterTransactionTempIdx, 1)}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiCloseBox)+" ")])],1),_c('v-card-text',{staticClass:"pt-2"},[_c('v-text-field',{staticClass:"my-4",attrs:{"label":"回収日時","hide-details":"","type":"datetime-local"},model:{value:(cashRegisterTransactionTemp.createdAt),callback:function ($$v) {_vm.$set(cashRegisterTransactionTemp, "createdAt", $$v)},expression:"cashRegisterTransactionTemp.createdAt"}}),_c('v-text-field',{attrs:{"label":"回収金額","hide-details":"","prefix":"¥","inputmode":"numeric","pattern":"[0-9]*","min":"0"},model:{value:(cashRegisterTransactionTemp.amount),callback:function ($$v) {_vm.$set(cashRegisterTransactionTemp, "amount", _vm._n($$v))},expression:"cashRegisterTransactionTemp.amount"}})],1)],1)}),_c('div',{staticClass:"w-full d-flex justify-center py-4"},[_c('v-btn',{attrs:{"fab":"","icon":"","large":"","ripple":false,"color":"primary"},on:{"click":function($event){return _vm.addCashRegisterTransactionTemp({})}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1)],1)]},proxy:true}],null,true)})]}},{key:"item.diff",fn:function(ref){
                var item = ref.item;
return [_vm._v(" ¥"+_vm._s((item.attributes.amount - item.meta.paidAmount).toLocaleString())+" ")]}},{key:"item.attributes.note",fn:function(ref){
                var item = ref.item;
return [_c('yona-edit-dialog',{key:((item.id) + "-" + (item.attributes.note)),attrs:{"display-name":item.attributes.note || '記入なし'},on:{"open":function($event){return _vm.edit({ note: item.attributes.note })},"close":_vm.cancelEdit,"save":function($event){return _vm.update(item.id)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-textarea',{attrs:{"placeholder":"ノート","rows":"3","prepend-icon":_vm.icons.mdiCommentQuoteOutline},model:{value:(_vm.editTarget.note),callback:function ($$v) {_vm.$set(_vm.editTarget, "note", $$v)},expression:"editTarget.note"}})]},proxy:true}],null,true)})]}},{key:"item.attributes.createdAt",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.dateTime(item.attributes.createdAt)))])]}},{key:"item.attributes.receiptId",fn:function(ref){
                var item = ref.item;
return [(item.attributes.receiptId)?_c('v-chip',{attrs:{"small":""},on:{"click":function($event){return _vm.$emit('open-receipt', item.attributes.receiptId)}}},[(_vm.isLoadingReceipt && +_vm.receipt.id === +item.attributes.receiptId)?_c('clip-loader',{staticClass:"d-flex align-items pr-2",attrs:{"loading":true,"color":'#8A8D93',"size":"16px"}}):_vm._e(),_vm._v(" 伝票 No."+_vm._s(item.attributes.receiptId)+" ")],1):_vm._e()]}},{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+" - "+_vm._s(props.pageStop)+" / "+_vm._s(props.itemsLength)+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }