<template>
  <div>
    <v-data-table
      ref="dataTable"
      :loading="isLoading"
      :headers="ReceiptHeaders"
      :items="receipts"
      :header-props="{ sortByText: 'ソート' }"
      :footer-props="{ itemsPerPageText: '行/ページ:' }"
      no-data-text="データがありません"
      :page="$route.query.page ? Number($route.query.page) : 1"
      class="cursor-pointer"
      @update:page="($event) => {
        $vuetify.goTo($refs.dataTable)
        addQuery({ page: $event })
      }"
    >
      <template #loading>
        <v-skeleton-loader type="table" />
      </template>

      <template #top>
        <div class="w-full d-flex">
          <v-spacer />
          <v-btn
            :loading="isLoading"
            :disabled="receipts.length <= 0 || isLoading"
            :ripple="false"
            color="secondary"
            small
            class="ma-2"
            @click="download"
          >
            <v-icon left>
              {{ icons.mdiFileExcel }}
            </v-icon>
            ダウンロード
          </v-btn>
        </div>
      </template>

      <template #[`item.meta.realTotal`]="{ item }">
        {{ item.meta.realTotal ? `¥${item.meta.realTotal.toLocaleString()}` : '¥0' }}
      </template>

      <template #[`item.attributes.subtotal`]="{ item }">
        {{ item.attributes.subtotal ? `¥${item.attributes.subtotal.toLocaleString()}` : '¥0' }}
      </template>

      <template #[`item.attributes.serviceFee`]="{ item }">
        {{ item.attributes.serviceFee ? `¥${item.attributes.serviceFee.toLocaleString()}` : '¥0' }}
      </template>

      <template #[`item.attributes.processingFee`]="{ item }">
        {{ item.attributes.processingFee ? `¥${item.attributes.processingFee.toLocaleString()}` : '-' }}
      </template>

      <template #[`item.attributes.discountFee`]="{ item }">
        {{ item.attributes.discountFee ? `¥${item.attributes.discountFee.toLocaleString()}` : '¥0' }}
      </template>

      <template #[`item.meta.totalCustomerPayAmount`]="{ item }">
        {{ item.meta.totalCustomerPayAmount ? `¥${item.meta.totalCustomerPayAmount.toLocaleString()}` : '¥0' }}
      </template>

      <template #[`item.attributes.change`]="{ item }">
        <span
          :class="Number(item.attributes.change) < 0 ? 'error--text' : ''"
        >
          {{ `¥${item.attributes.change.toLocaleString()}` }}
        </span>
      </template>

      <template #[`item.attributes.createdAt`]="{item}">
        <span class="text-no-wrap">{{ dateTime(item.attributes.createdAt) }}</span>
      </template>

      <template #[`item.id`]="{item}">
        <v-chip
          v-if="item.id"
          small
          @click="$emit('open-receipt', item.id)"
        >
          <clip-loader
            v-if="isLoadingReceipt && +receipt.id === +item.id"
            :loading="true"
            :color="'#8A8D93'"
            size="16px"
            class="d-flex align-items pr-2"
          />
          伝票 No.{{ item.id }}
        </v-chip>
      </template>

      <template #[`footer.page-text`]="props">
        {{ props.pageStart }} - {{ props.pageStop }} / {{ props.itemsLength }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { ref, inject } from '@vue/composition-api'
import { mdiEyeOffOutline, mdiFileExcel } from '@mdi/js'
import * as XLSX from 'xlsx/xlsx.mjs'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import useDateFormat from '@/views/composable/useDateFormat'
import useQueryString from '@/views/composable/useQueryString'
import useCompInit from '@/views/composable/useCompInit'
import useReceiptsDataTable from '@/views/composable/data-table/useReceiptsDataTable'
import ReceiptApi from '@/api/waiter/Receipt'
import { ReceiptHeaders } from '@/utils/constants/table-headers'

export default {
  components: {
    ClipLoader,
  },
  props: {
    date: {
      type: String,
      required: true,
      default: () => new Date().toISOString().substr(0, 10),
    },
    interval: {
      type: Number,
      required: true,
      default: 0,
    },
    endDate: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { route, addQuery } = useQueryString()
    const { isLoading, initWith } = useCompInit()
    const receipts = ref([])
    const receipt = inject('receipt', {})
    const isLoadingReceipt = inject('isLoadingReceipt', false)
    const isReceiptOpen = inject('isReceiptOpen', false)
    const isDownloading = ref(false)

    const { dateTime } = useDateFormat()
    const { buildXlsxData } = useReceiptsDataTable(receipts)

    const getReceipts = async ({
      date = null,
      interval = null,
      endDate = null,
      id = null,
    }) => {
      const res = await ReceiptApi.getReceipts({
        date,
        interval,
        endDate,
        id,
      })

      if (res?.status === 200) {
        receipts.value = [...res.data.receipts.data]
      }
    }

    const download = () => {
      isDownloading.value = true

      const receiptExcel = buildXlsxData()

      const wb = XLSX.utils.book_new()
      const data = XLSX.utils.json_to_sheet(receiptExcel)
      XLSX.utils.book_append_sheet(wb, data, '伝票')

      let title = `${props.date}_${['日', '週', '月'][props.interval]}`
      if (props.endDate) title = `${props.date}~${props.endDate}`
      XLSX.writeFile(wb, `伝票_${title}.xlsx`)

      isDownloading.value = false
    }

    initWith([
      getReceipts(route.value.query),
    ])

    return {
      // data
      receipts,
      isLoading,
      ReceiptHeaders,
      receipt,
      isLoadingReceipt,
      isReceiptOpen,

      // methods
      getReceipts,
      dateTime,
      addQuery,
      download,

      icons: {
        mdiEyeOffOutline,
        mdiFileExcel,
      },
    }
  },
}
</script>
