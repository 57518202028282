import { serialize } from 'object-to-formdata'
import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getReferringHistories({
    date = null,
    interval = null,
    referralId = null,
    userId = null,
    tableId = null,
    endDate = null,
  }) {
    return axiosIns.get(
      '/waiter/referring_histories',
      {
        params: snakecaseKeys({
          date,
          interval,
          referralId,
          userId,
          tableId,
          endDate,
        }),
      },
    )
  },
  updateReferringHistories({ tableId, referringHistories }) {
    const formData = serialize(snakecaseKeys({
      table: {
        referringHistoriesAttributes: referringHistories,
      },
    }))

    return axiosIns.put(`waiter/tables/${tableId}/referring_histories`, formData)
  },
  deleteReferringHistory(id) {
    return axiosIns.delete(`/waiter/referring_histories/${id}`)
  },
}
