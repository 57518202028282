import { serialize } from 'object-to-formdata'
import snakecaseKeys from 'snakecase-keys'
import qs from 'qs'
import axiosIns from '@/plugins/axios'

export default {
  getTimecards({
    date = null,
    interval = null,
    userId = null,
    role = null,
    isWorking = false,
    endDate = null,
    isToday = false,
  }) {
    const config = {
      params: snakecaseKeys({
        date,
        interval,
        userId,
        role,
        isWorking,
        endDate,
        isToday,
      }),
      paramsSerializer(params) {
        return qs.stringify(params)
      },
    }

    return axiosIns.get('/waiter/timecards', config)
  },

  /**
  * @param {Timecard} timecard  create timecard based on this (argument) variable information。
  */
  createTimecard(timecard) {
    const formData = serialize(snakecaseKeys({
      timecard,
      userId: timecard.userId,
    }))

    // TODO error handling
    return axiosIns.post('/waiter/timecards', formData)
  },

  /**
  * @param {Timecard} timecard  update timecard based on this (argument) variable information。
  */
  updateTimecard(timecard) {
    const formData = serialize(snakecaseKeys({
      timecard,
    }))

    return axiosIns.put(`/waiter/timecards/${timecard.id}`, formData)
  },

  deleteTimecard(id) {
    return axiosIns.delete(`/waiter/timecards/${id}`)
  },
}
