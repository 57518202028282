<template>
  <div>
    <v-data-table
      ref="dataTable"
      :loading="isLoading"
      :headers="BalanceReceivableHeaders"
      :items="balanceReceivables"
      :header-props="{ sortByText: 'ソート' }"
      :footer-props="{ itemsPerPageText: '行/ページ:' }"
      no-data-text="データがありません"
      :page="$route.query.page ? Number($route.query.page) : 1"
      class="cursor-pointer"
      @update:page="($event) => {
        $vuetify.goTo($refs.dataTable)
        addQuery({ page: $event })
      }"
    >
      <template #loading>
        <v-skeleton-loader type="table" />
      </template>

      <template #top>
        <div class="w-full d-flex align-center">
          <switcher
            bool-key-name="isPaid"
            label="回収済"
            color="success"
            class="pa-0 ma-0 ml-2"
            @switched="getBalanceReceivables({ ...$event, ...$route.query })"
          />
          <v-spacer />
          <v-btn
            :loading="isLoading"
            :disabled="balanceReceivables.length <= 0 || isLoading"
            :ripple="false"
            color="secondary"
            small
            class="ma-2"
            @click="download"
          >
            <v-icon left>
              {{ icons.mdiFileExcel }}
            </v-icon>
            ダウンロード
          </v-btn>
        </div>
      </template>

      <template #[`item.meta.paid`]="{ item }">
        <v-avatar
          :color="item.attributes.amount <= item.meta.paidAmount ? 'success' : 'error'"
          size="16"
        />
      </template>

      <template #[`item.attributes.amount`]="{ item }">
        {{ `¥${item.attributes.amount.toLocaleString()}` }}
      </template>

      <template #[`item.meta.paidAmount`]="{ item }">
        <yona-edit-dialog
          :key="JSON.stringify(item)"
          :display-name="`¥${item.meta.paidAmount.toLocaleString()}`"
          @open="edit({ cashRegisterTransactionsAttributes: initCashRegisterTransactionsTemp(item.attributes.cashRegisterTransactions.data || []) })"
          @close="cancelEdit"
          @save="update(item.id)"
        >
          <template #input>
            <v-card
              v-for="(cashRegisterTransactionTemp, cashRegisterTransactionTempIdx) in cashRegisterTransactionsTemp"
              :key="`cashRegisterTransactionsTemp[${cashRegisterTransactionTempIdx}]`"
              class="mb-4"
              :class="cashRegisterTransactionTemp._destroy ? '_destroy-color' : ''"
            >
              <v-btn
                icon
                fab
                :ripple="false"
                class="remove-btn"
                @click="cashRegisterTransactionTemp.id
                  ? cashRegisterTransactionTemp._destroy = !cashRegisterTransactionTemp._destroy
                  : cashRegisterTransactionsTemp.splice(cashRegisterTransactionTempIdx, 1)"
              >
                <v-icon>
                  {{ icons.mdiCloseBox }}
                </v-icon>
              </v-btn>

              <v-card-text class="pt-2">
                <v-text-field
                  v-model="cashRegisterTransactionTemp.createdAt"
                  label="回収日時"
                  hide-details
                  type="datetime-local"
                  class="my-4"
                />
                <v-text-field
                  v-model.number="cashRegisterTransactionTemp.amount"
                  label="回収金額"
                  hide-details
                  prefix="¥"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  min="0"
                />
              </v-card-text>
            </v-card>

            <div class="w-full d-flex justify-center py-4">
              <v-btn
                fab
                icon
                large
                :ripple="false"
                color="primary"
                @click="addCashRegisterTransactionTemp({})"
              >
                <v-icon>
                  {{ icons.mdiPlus }}
                </v-icon>
              </v-btn>
            </div>
          </template>
        </yona-edit-dialog>
      </template>

      <template #[`item.diff`]="{ item }">
        ¥{{ (item.attributes.amount - item.meta.paidAmount).toLocaleString() }}
      </template>

      <template #[`item.attributes.note`]="{ item }">
        <yona-edit-dialog
          :key="`${item.id}-${item.attributes.note}`"
          :display-name="item.attributes.note || '記入なし'"
          @open="edit({ note: item.attributes.note })"
          @close="cancelEdit"
          @save="update(item.id)"
        >
          <template #input>
            <v-textarea
              v-model="editTarget.note"
              placeholder="ノート"
              rows="3"
              :prepend-icon="icons.mdiCommentQuoteOutline"
            />
          </template>
        </yona-edit-dialog>
      </template>

      <template #[`item.attributes.createdAt`]="{item}">
        <span class="text-no-wrap">{{ dateTime(item.attributes.createdAt) }}</span>
      </template>

      <template #[`item.attributes.receiptId`]="{ item }">
        <v-chip
          v-if="item.attributes.receiptId"
          small
          @click="$emit('open-receipt', item.attributes.receiptId)"
        >
          <clip-loader
            v-if="isLoadingReceipt && +receipt.id === +item.attributes.receiptId"
            :loading="true"
            :color="'#8A8D93'"
            size="16px"
            class="d-flex align-items pr-2"
          />
          伝票 No.{{ item.attributes.receiptId }}
        </v-chip>
      </template>

      <template #[`footer.page-text`]="props">
        {{ props.pageStart }} - {{ props.pageStop }} / {{ props.itemsLength }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {
  ref,
  getCurrentInstance,
  inject,
} from '@vue/composition-api'
import {
  mdiCommentQuoteOutline,
  mdiPlus,
  mdiCloseBox,
  mdiFileExcel,
} from '@mdi/js'
import { each } from 'lodash'
import * as XLSX from 'xlsx/xlsx.mjs'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import useDataTableEdit from '@/views/composable/useDataTableEdit'
import BalanceReceivableApi from '@/api/waiter/BalanceReceivable'
import useDateFormat from '@/views/composable/useDateFormat'
import useQueryString from '@/views/composable/useQueryString'
import useBalanceReceivablesDataTable from '@/views/composable/data-table/useBalanceReceivablesDataTable'
import { BalanceReceivableHeaders } from '@/utils/constants/table-headers'
import Switcher from '@/views/components/util/filter/Switcher.vue'
import YonaEditDialog from '@/views/components/util/YonaEditDialog.vue'

export default {
  components: {
    Switcher,
    ClipLoader,
    YonaEditDialog,
  },
  props: {
    date: {
      type: String,
      required: true,
      default: () => new Date().toISOString().substr(0, 10),
    },
    interval: {
      type: Number,
      required: true,
      default: 0,
    },
    endDate: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const vm = getCurrentInstance().proxy

    const isLoading = ref(false)
    const isSubmitting = ref(false)
    const balanceReceivables = ref([])
    const targetBalanceReceivable = ref({})
    const cashRegisterTransactionsTemp = ref([])
    const {
      isEditing,
      editTarget,
      cancelEdit,
      edit,
      isEditingHere,
    } = useDataTableEdit()
    const isDownloading = ref(false)
    const receipt = inject('receipt', {})
    const isLoadingReceipt = inject('isLoadingReceipt', false)
    const isReceiptOpen = inject('isReceiptOpen', false)

    const { dateTime, dateTimeForJsFriendly } = useDateFormat()
    const { route, addQuery } = useQueryString()
    const { buildXlsxData } = useBalanceReceivablesDataTable(balanceReceivables)

    const addCashRegisterTransactionTemp = ({
      id = null,
      amount = 0,
      createdAt = dateTimeForJsFriendly(new Date()).split(' ').join('T'),
      _destroy = false,
    }) => {
      cashRegisterTransactionsTemp.value.push({
        id,
        amount,
        createdAt,
        _destroy,
      })
    }

    // NOTE: 編集は不可。削除と新規作成のみにする
    const initCashRegisterTransactionsTemp = cashRegisterTransactionsData => {
      cashRegisterTransactionsTemp.value = []

      each(cashRegisterTransactionsData, tx => {
        addCashRegisterTransactionTemp({
          id: tx.id,
          amount: tx.attributes.amount,
          createdAt: dateTimeForJsFriendly(tx.attributes.createdAt),
        })
      })

      return cashRegisterTransactionsTemp
    }

    const getBalanceReceivables = async ({
      date = null,
      interval = null,
      endDate = null,
      isPaid = false,
    }) => {
      isLoading.value = true

      const res = await BalanceReceivableApi.getBalanceReceivables({
        date,
        interval,
        endDate,
        isPaid,
      })

      if (res?.status === 200) {
        balanceReceivables.value = res.data.balanceReceivables.data
      }

      isLoading.value = false
    }

    const update = async id => {
      isSubmitting.value = true

      const res = await BalanceReceivableApi.updateBalanceReceivable({
        id,
        ...editTarget.value,
      })

      if (res?.status === 200) {
        const index = balanceReceivables.value.findIndex(o => +o.id === +id)
        balanceReceivables.value.splice(index, 1, res.data.data)
        vm.$toast.success('売掛を更新しました')
      }

      isSubmitting.value = false
    }

    const download = () => {
      isDownloading.value = true

      const balanceReceivablesExcel = buildXlsxData()

      const wb = XLSX.utils.book_new()
      const data = XLSX.utils.json_to_sheet(balanceReceivablesExcel)
      XLSX.utils.book_append_sheet(wb, data, '売掛')

      let title = `${props.date}_${['日', '週', '月'][props.interval]}`
      if (props.endDate) title = `${props.date}~${props.endDate}`
      XLSX.writeFile(wb, `売掛_${title}.xlsx`)
      isDownloading.value = false
    }

    getBalanceReceivables(route.value.query)

    return {
      // data
      isLoading,
      isSubmitting,
      balanceReceivables,
      targetBalanceReceivable,
      BalanceReceivableHeaders,
      isEditing,
      editTarget,
      cancelEdit,
      edit,
      isEditingHere,
      receipt,
      isLoadingReceipt,
      isReceiptOpen,
      cashRegisterTransactionsTemp,
      addCashRegisterTransactionTemp,

      // methods
      dateTime,
      update,
      addQuery,
      getBalanceReceivables,
      initCashRegisterTransactionsTemp,
      download,

      icons: {
        mdiCommentQuoteOutline,
        mdiPlus,
        mdiCloseBox,
        mdiFileExcel,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.remove-btn {
  position: absolute;
  top: -8px;
  right: -8px;
}
._destroy-color {
  opacity: 0.5;
  filter: hue-rotate(30deg);
}
</style>
