import { serialize } from 'object-to-formdata'
import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getCustomerTags() {
    return axiosIns.get('/waiter/customer_tags')
  },
  createCustomerTag({ name }) {
    const formData = serialize(snakecaseKeys({
      customerTag: { name },
    }))

    return axiosIns.post('/waiter/customer_tags', formData)
  },
  updateCustomerTag({ id, name }) {
    const formData = serialize(snakecaseKeys({
      customerTag: { name },
    }))

    return axiosIns.put(`/waiter/customer_tags/${id}`, formData)
  },
  deleteCustomerTag(id) {
    return axiosIns.delete(`/waiter/customer_tags/${id}`)
  },
  updatePositions(idAndPositions) {
    const formData = serialize(snakecaseKeys({
      customerTags: idAndPositions,
    }))

    return axiosIns.put('/waiter/customer_tags/update_positions', formData)
  },
}
