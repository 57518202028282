<template>
  <div>
    <v-data-table
      ref="dataTable"
      :headers="headers"
      :items="tables"
      :header-props="{ sortByText: 'ソート' }"
      :footer-props="{ itemsPerPageText: '行/ページ:' }"
      :loading="isLoading || isLoadingTables"
      no-data-text="データがありません"
      :page="$route.query.page ? Number($route.query.page) : 1"
      @update:page="($event) => {
        $vuetify.goTo($refs.dataTable)
        addQuery({ page: $event })
      }"
    >
      <template #loading>
        <v-skeleton-loader type="table" />
      </template>

      <template #top>
        <div class="w-full d-flex align-center">
          <v-menu
            v-model="isFilterMenuOpen"
            :close-on-content-click="false"
            min-width="300"
            offset-y
          >
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                class="ml-2"
              >
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiFilterMenuOutline }}
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-subheader>ホール</v-subheader>
              <v-list-item>
                <v-list-item-content class="px-2">
                  <simple-select
                    :items="halls"
                    :item-id-key-name="'hallId'"
                    :prepend-inner-icon="icons.mdiDotsSquare"
                    class="pa-0 ma-0"
                    @selected="getTables({ ...$event, ...$route.query })"
                    @cleared="getTables($route.query)"
                  />
                </v-list-item-content>
              </v-list-item>

              <v-subheader>卓番</v-subheader>
              <v-list-item>
                <v-list-item-content class="px-2">
                  <v-select
                    v-model="tableNumberToFilter"
                    :items="tableNumbers[Number($route.query.hallId)] || maxTableNumbers"
                    :menu-props="{ auto: true, offsetY: true }"
                    :prepend-inner-icon="icons.mdiCounter"
                    clearable
                    hide-details
                    hide-selected
                    no-data-text="選択可能なデータがありません"
                    class="pa-0 ma-0"
                    @change="addQuery({ tableNumber: tableNumberToFilter }); getTables({ ...$route.query, tableNumber: tableNumberToFilter })"
                    @click:clear="removeQuery('tableNumber'); tableNumberToFilter = null; getTables($route.query)"
                  />
                </v-list-item-content>
              </v-list-item>

              <v-subheader>顧客タグ</v-subheader>
              <v-list-item>
                <v-list-item-content class="px-2">
                  <multiple-select
                    :items="customerTags"
                    :item-id-key-name="'customerTagId'"
                    :prepend-inner-icon="icons.mdiPound"
                    class="pa-0 ma-0"
                    @selected="getTables({ ...$event, ...$route.query })"
                    @cleared="getTables($route.query)"
                  />
                </v-list-item-content>
              </v-list-item>

              <v-subheader>{{ currentClub.referralAlias || 'リファラル' }}</v-subheader>
              <v-list-item>
                <v-list-item-content class="px-2">
                  <simple-select
                    :items="referrals"
                    :item-id-key-name="'referralId'"
                    :prepend-inner-icon="icons.mdiVectorLink"
                    class="pa-0 ma-0"
                    @selected="getTables({ ...$event, ...$route.query })"
                    @cleared="getTables($route.query)"
                  />
                </v-list-item-content>
              </v-list-item>
              <v-subheader />
            </v-list>
          </v-menu>
          <v-spacer />
          <v-btn
            :loading="isLoading"
            :disabled="tables.length <= 0 || isLoading"
            :ripple="false"
            color="secondary"
            small
            class="ma-2"
            @click="download"
          >
            <v-icon left>
              {{ icons.mdiFileExcel }}
            </v-icon>
            ダウンロード
          </v-btn>
        </div>
      </template>

      <template #[`item.meta.tableName`]="{item}">
        <yona-edit-dialog
          :display-name="item.meta.tableName"
          :is-valid="isValid.hallId && isValid.tableNumber"
          @open="edit({ hallId: item.attributes.hallId, tableNumber: item.attributes.tableNumber })"
          @close="cancelEdit"
          @save="update(item.id)"
        >
          <template #input>
            <v-select
              v-model="editTarget.hallId"
              :items="halls"
              :item-value="item => Number(item.id)"
              item-text="attributes.name"
              label="ホール"
              :error="!isValid.hallId"
              :prepend-inner-icon="icons.mdiDotsSquare"
              single-line
              :menu-props="{ auto: true, offsetY: true }"
              no-data-text="選択可能なデータがありません"
            />

            <v-select
              v-model="editTarget.tableNumber"
              :items="tableNumbers[editTarget.hallId]"
              label="卓番号"
              :error="!isValid.tableNumber"
              single-line
              :menu-props="{ auto: true, offsetY: true }"
              no-data-text="選択可能なデータがありません"
            />
          </template>
        </yona-edit-dialog>
      </template>

      <template #[`item.attributes.customerCount`]="{item}">
        <yona-edit-dialog
          :display-name="item.attributes.customerCount"
          @open="edit({ customerCount: item.attributes.customerCount })"
          @close="cancelEdit"
          @save="update(item.id)"
        >
          <template #input>
            <v-text-field
              v-model.number="editTarget.customerCount"
              type="number"
              inputmode="numeric"
              placeholder="客数"
              pattern="[0-9]*"
              min="1"
            />
          </template>
        </yona-edit-dialog>
      </template>

      <template #[`item.customerTags`]="{item}">
        <yona-edit-dialog
          :is-valid="isValid.customerTags"
          @open="isEditing = true"
          @close="cancelEdit"
          @save="update(item.id)"
        >
          <template #append-outer-display-name>
            <v-chip-group
              v-if="item.attributes.customerTableTaggings.data.length > 0"
              column
            >
              <v-chip
                v-for="customerTableTagging in item.attributes.customerTableTaggings.data"
                :key="`customerTableTagging${customerTableTagging.id}`"
                small
              >
                {{ customerTableTagging.attributes.customerTag.data.attributes.name }}
              </v-chip>
            </v-chip-group>

            <v-btn
              v-else
              icon
              :ripple="false"
            >
              <v-icon small>
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </template>

          <template #input>
            <customer-table-tagging
              :table-id="item.id"
              :customer-table-taggings="item.attributes.customerTableTaggings.data"
              :update-every-time="false"
              class="mt-4"
              @updated="editTarget = { customerTableTaggingsAttributes: $event }"
              @init:init-func="initCustomerTableTaggingsAttributes = $event"
            />
          </template>
        </yona-edit-dialog>
      </template>

      <template #[`item.referrals`]="{item}">
        <yona-edit-dialog
          :is-valid="isValid.referringHistories"
          @open="edit({ referringHistoriesAttributes: initReferringHistoriesTemp(item.attributes.referringHistories.data || []) })"
          @close="cancelEdit"
          @save="update(item.id)"
        >
          <template #append-outer-display-name>
            <v-chip-group
              v-if="item.attributes.referringHistories.data.length > 0"
              column
            >
              <span
                v-for="referringHistory in item.attributes.referringHistories.data"
                :key="`referringHistory${referringHistory.id}`"
              >
                {{ referringHistory.attributes.user.data.attributes.name }}
                <v-chip small>
                  {{ referringHistory.attributes.referral.data.attributes.name }}
                </v-chip>
              </span>
            </v-chip-group>

            <v-btn
              v-else
              icon
              :ripple="false"
            >
              <v-icon small>
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </template>

          <template #input>
            <div class="mt-8" />
            <!-- eslint-disable vue/v-on-event-hyphenation -->
            <referral-card
              v-for="(referringHistoryTemp, referringHistoryTempIdx) in referringHistoriesTemp"
              :key="`referringHistoriesTemp[${referringHistoryTempIdx}]`"
              :value="referringHistoryTemp"
              :referrals="referrals"
              :users="users"
              :referring-history-temp-idx="referringHistoryTempIdx"
              class="mb-8"
              @update:referralId="referringHistoryTemp.referralId = $event"
              @update:userId="referringHistoryTemp.userId = $event"
              @update:_destroy="referringHistoryTemp._destroy = $event"
              @remove="removeReferringHistoryTemp($event)"
            />

            <v-card
              elevation="0"
              class="d-flex align-center justify-center mb-2"
            >
              <v-btn
                fab
                icon
                large
                :ripple="false"
                color="primary"
                @click="addReferringHistoryTemp({})"
              >
                <v-icon>
                  {{ icons.mdiPlus }}
                </v-icon>
              </v-btn>
            </v-card>
          </template>
        </yona-edit-dialog>
      </template>

      <template #[`item.attributes.startAt`]="{item}">
        <yona-edit-dialog
          :display-name="dateTime(item.attributes.startAt)"
          @open="edit({ id: item.id, startAt: dateTimeForJsFriendly(item.attributes.startAt) })"
          @save="update(item.id)"
        >
          <template #input>
            <v-text-field
              v-model="editTarget.startAt"
              type="datetime-local"
              class="pa-0 ma-0"
            />
          </template>
        </yona-edit-dialog>
      </template>

      <template #[`item.attributes.receipt.data.attributes.createdAt`]="{item}">
        <span v-if="item.attributes.receipt && item.attributes.receipt.data">
          {{ dateTime(item.attributes.receipt.data.attributes.createdAt) }}
        </span>
      </template>

      <template #[`item.attributes.receipt.data.id`]="{item}">
        <v-chip
          v-if="item.attributes.receipt && item.attributes.receipt.data.id"
          small
          @click="$emit('open-receipt', item.attributes.receipt.data.id)"
        >
          <clip-loader
            v-if="isLoadingReceipt && +receipt.id === +item.attributes.receipt.data.id"
            :loading="true"
            :color="'#8A8D93'"
            size="16px"
            class="d-flex align-items pr-2"
          />
          伝票 No.{{ item.attributes.receipt.data.id }}
        </v-chip>
        <v-chip
          v-else
          color="success"
          class="v-chip-light-bg success--text"
          small
        >
          進行中の卓
        </v-chip>
      </template>

      <template #[`item.actions`]="{item}">
        <div class="d-flex align-center justify-end flex-wrap">
          <yona-edit-dialog
            title="削除の確認"
            btn-color="error"
            save-text="同意の上で削除"
            @save="destroy(item.id)"
          >
            <template #append-outer-display-name>
              <v-btn
                icon
                :ripple="false"
              >
                <v-icon small>
                  {{ icons.mdiTrashCanOutline }}
                </v-icon>
              </v-btn>
            </template>

            <template #input>
              <span>
                削除を実行してもよろしいですか（この卓に紐づけたすべての履歴も削除します。）
              </span>
            </template>
          </yona-edit-dialog>
        </div>
      </template>

      <template #[`footer.page-text`]="props">
        {{ props.pageStart }} - {{ props.pageStop }} / {{ props.itemsLength }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {
  ref,
  computed,
  getCurrentInstance,
  watch,
  inject,
} from '@vue/composition-api'
import {
  mdiCalendar,
  mdiDotsSquare,
  mdiPound,
  mdiVectorLink,
  mdiTrashCanOutline,
  mdiPlus,
  mdiClockOutline,
  mdiCheckCircleOutline,
  mdiCloseCircleOutline,
  mdiFileExcel,
  mdiFilterMenuOutline,
  mdiCounter,
} from '@mdi/js'
import {
  each,
  range,
  map,
  maxBy,
  includes,
  every,
} from 'lodash'
import * as XLSX from 'xlsx/xlsx.mjs'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import TableApi from '@/api/waiter/Table'
import HallApi from '@/api/waiter/Hall'
import CustomerTagApi from '@/api/waiter/CustomerTag'
import useDateFormat from '@/views/composable/useDateFormat'
import useQueryString from '@/views/composable/useQueryString'
import useCurrentData from '@/views/composable/useCurrentData'
import useReferral from '@/views/composable/useReferral'
import useDataTableEdit from '@/views/composable/useDataTableEdit'
import useCompInit from '@/views/composable/useCompInit'
import useValidation from '@/views/composable/useValidation'
import useTablesDataTable from '@/views/composable/data-table/useTablesDataTable'
import SimpleSelect from '@/views/components/util/filter/SimpleSelect.vue'
import MultipleSelect from '@/views/components/util/filter/MultipleSelect.vue'
import YonaEditDialog from '@/views/components/util/YonaEditDialog.vue'
import CustomerTableTagging from '@/views/components/table/CustomerTableTagging.vue'
import ReferralCard from '@/views/components/table/ReferralCard.vue'

export default {
  components: {
    SimpleSelect,
    MultipleSelect,
    YonaEditDialog,
    CustomerTableTagging,
    ReferralCard,
    ClipLoader,
  },
  props: {
    date: {
      type: String,
      required: true,
      default: () => new Date().toISOString().substr(0, 10),
    },
    interval: {
      type: Number,
      required: true,
      default: 0,
    },
    endDate: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy
    const { route, addQuery, removeQuery } = useQueryString()
    const { dateTime, hhmm, dateTimeForJsFriendly } = useDateFormat()
    const { currentClub } = useCurrentData()
    const {
      referringHistoriesTemp,
      addReferringHistoryTemp,
      removeReferringHistoryTemp,
      resetReferringHistoriesTemp,
      isValidToSubmit: isValidReferringHistories,
    } = useReferral()
    const { isLoading, initWith } = useCompInit()
    const { isValidDateTimeAttributes } = useValidation()
    const isLoadingTables = ref(false)
    const dataTable = ref()
    const tables = ref([])
    const halls = ref([])
    const tableNumberToFilter = ref(route.value.query.tableNumber)
    const customerTags = ref([])
    const initCustomerTableTaggingsAttributes = ref(() => {})
    const users = inject('users', [])
    const referrals = inject('referrals', [])
    const receipt = inject('receipt', {})
    const isLoadingReceipt = inject('isLoadingReceipt', false)
    const isReceiptOpen = inject('isReceiptOpen', false)
    const isDownloading = ref(false)
    const isFilterMenuOpen = ref(false)

    const {
      isEditing,
      editTarget,
      cancelEdit: utilCancelEdit,
      edit,
      isEditingHere,
    } = useDataTableEdit()

    const { buildXlsxData, headers } = useTablesDataTable(tables)

    const tableNumbers = computed(() => {
      const obj = {}

      each(halls.value, hall => {
        obj[hall.id] = range(1, Number(hall.attributes.tableCount) + 1)
      })

      return obj
    })

    const maxTableNumbers = computed(() => {
      const tableCounts = map(halls.value, 'attributes.tableCount')

      return range(1, maxBy(tableCounts, Number) + 1)
    })

    const isValid = computed(() => {
      const {
        hallId,
        tableNumber,
        customerTableTaggingsAttributes,
      } = editTarget.value

      return {
        hallId: includes(map(halls.value, o => Number(o.id)), Number(hallId)),
        tableNumber: includes(tableNumbers.value[hallId], Number(tableNumber)),
        customerTags: !!customerTableTaggingsAttributes && every(customerTableTaggingsAttributes, 'tableId'),
        referringHistories: isValidReferringHistories.value,
      }
    })

    const initReferringHistoriesTemp = referringHistoriesData => {
      each(referringHistoriesData, referringHistory => {
        addReferringHistoryTemp({
          id: referringHistory.id,
          userId: referringHistory.attributes.user.data.id,
          referralId: referringHistory.attributes.referral.data.id,
        })
      })

      return referringHistoriesTemp
    }

    const destroy = async id => {
      const res = await TableApi.deleteTable(id)

      if (res) {
        const index = tables.value.findIndex(o => +o.id === +id)
        tables.value.splice(index, 1)
        vm.$toast.success('卓を削除しました')
      }
    }

    const cancelEdit = () => {
      utilCancelEdit()
      resetReferringHistoriesTemp()
    }

    const update = async editTableId => {
      const res = await TableApi.updateTable({
        tableId: editTableId,
        params: editTarget.value,
      })

      if (res?.data?.data) {
        const table = res.data.data
        const index = tables.value.findIndex(o => +o.id === +editTableId)
        tables.value.splice(index, 1, table)
        vm.$toast.success('卓を更新しました')

        initCustomerTableTaggingsAttributes.value(table.attributes.customerTableTaggings.data)
      }

      cancelEdit()
    }

    const getTables = async ({
      date = null,
      interval = null,
      hallId = null,
      tableNumber = null,
      customerTagId = null,
      referralId = null,
      tableId = null,
      endDate = null,
    }) => {
      isLoadingTables.value = true

      const res = await TableApi.getTables({
        date,
        interval,
        hallId,
        tableNumber,
        customerTagId,
        referralId,
        tableId,
        endDate,
      })

      if (res?.status === 200) {
        tables.value = [...res.data.tables.data]
      }

      isLoadingTables.value = false
    }

    const getHalls = async () => {
      const res = await HallApi.getHalls({})

      if (res?.status === 200) {
        halls.value = [...res.data.halls.data]
      }
    }

    const getCustomerTags = async () => {
      const res = await CustomerTagApi.getCustomerTags()

      if (res?.status === 200) {
        customerTags.value = [...res.data.customerTags.data]
      }
    }

    const download = () => {
      isDownloading.value = true

      const tablesExcel = buildXlsxData()

      const wb = XLSX.utils.book_new()
      const data = XLSX.utils.json_to_sheet(tablesExcel)
      XLSX.utils.book_append_sheet(wb, data, '卓')

      let title = `${props.date}_${['日', '週', '月'][props.interval]}`
      if (props.endDate) title = `${props.date}~${props.endDate}`
      XLSX.writeFile(wb, `卓_${title}.xlsx`)

      isDownloading.value = false
    }

    initWith([
      getTables(route.value.query),
      getHalls(),
      getCustomerTags(),
    ])

    watch(() => isEditing.value, newVal => {
      emit('toggle-form', newVal)
    })

    return {
      // data
      dataTable,
      isLoading,
      isLoadingTables,
      isEditing,
      tables,
      headers,
      halls,
      tableNumberToFilter,
      customerTags,
      referrals,
      users,
      editTarget,
      initCustomerTableTaggingsAttributes,
      referringHistoriesTemp,
      receipt,
      isLoadingReceipt,
      isReceiptOpen,
      isFilterMenuOpen,

      // computed
      tableNumbers,
      maxTableNumbers,
      isValid,

      // methods
      dateTime,
      hhmm,
      addQuery,
      removeQuery,
      getTables,
      destroy,
      update,
      edit,
      cancelEdit,
      isEditingHere,
      initReferringHistoriesTemp,
      removeReferringHistoryTemp,
      addReferringHistoryTemp,
      dateTimeForJsFriendly,
      download,

      icons: {
        mdiCalendar,
        mdiDotsSquare,
        mdiPound,
        mdiVectorLink,
        mdiTrashCanOutline,
        mdiPlus,
        mdiClockOutline,
        mdiCheckCircleOutline,
        mdiCloseCircleOutline,
        mdiFileExcel,
        mdiFilterMenuOutline,
        mdiCounter,
      },
    }
  },
}
</script>
