import snakecaseKeys from 'snakecase-keys'
import { serialize } from 'object-to-formdata'
import axiosIns from '@/plugins/axios'

export default {
  getHalls({
    isOperation = false,
    isSettings = false,
  }) {
    return axiosIns.get(
      '/waiter/halls',
      {
        params: snakecaseKeys({
          isOperation,
          isSettings,
        }),
      },
    )
  },
  updateHall({ hallId, params }) {
    const formData = serialize(snakecaseKeys({ hall: params }))

    return axiosIns.put(`/waiter/halls/${hallId}/`, formData)
  },
  createHall({ params }) {
    const formData = serialize(snakecaseKeys({ hall: params }))

    return axiosIns.post('/waiter/halls/', formData)
  },
  deleteHall({ hallId }) {
    return axiosIns.delete(`/waiter/halls/${hallId}`)
  },
}
