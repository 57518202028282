import { serialize } from 'object-to-formdata'
import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getVendingContributionTriggers() {
    return axiosIns.get('/waiter/vending_contribution_triggers')
  },
  createVendingContributionTrigger({ triggerableId, triggerableType }) {
    const formData = serialize(snakecaseKeys({
      vendingContributionTrigger: { triggerableId, triggerableType },
    }))

    return axiosIns.post('/waiter/vending_contribution_triggers', formData)
  },
  deleteVendingContributionTrigger(id) {
    return axiosIns.delete(`/waiter/vending_contribution_triggers/${id}`)
  },
}
