<template>
  <div>
    <v-data-table
      ref="dataTable"
      :loading="isLoading || isLoadingReferringHistories"
      :headers="ReferringHistoriesHeaders"
      :items="referringHistories"
      :header-props="{ sortByText: 'ソート' }"
      :footer-props="{ itemsPerPageText: '行/ページ:' }"
      no-data-text="データがありません"
      :page="$route.query.page ? Number($route.query.page) : 1"
      @update:page="($event) => {
        $vuetify.goTo($refs.dataTable)
        addQuery({ page: $event })
      }"
    >
      <template #loading>
        <v-skeleton-loader type="table" />
      </template>

      <template #top>
        <div class="w-full d-flex align-center">
          <v-menu
            v-model="isFilterMenuOpen"
            :close-on-content-click="false"
            min-width="300"
            offset-y
          >
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                class="ml-2"
              >
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiFilterMenuOutline }}
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-subheader>種別</v-subheader>
              <v-list-item>
                <v-list-item-content class="px-2">
                  <simple-select
                    :items="referrals"
                    :item-id-key-name="'referralId'"
                    :prepend-inner-icon="icons.mdiVectorLink"
                    class="pa-0 ma-0"
                    @selected="getReferringHistories({ ...$event, ...$route.query})"
                    @cleared="getReferringHistories($route.query)"
                  />
                </v-list-item-content>
              </v-list-item>

              <v-subheader>ユーザー</v-subheader>
              <v-list-item>
                <v-list-item-content class="px-2">
                  <user-select
                    :users="users"
                    :role-order="['alliance', 'cast', 'waiter']"
                    class="pa-0 ma-0"
                    @selected="getReferringHistories({ ...$event, ...$route.query })"
                    @cleared="getReferringHistories($route.query)"
                  />
                </v-list-item-content>
              </v-list-item>
              <v-subheader />
            </v-list>
          </v-menu>
          <v-spacer />
          <v-btn
            :loading="isLoading"
            :disabled="referringHistories.length <= 0 || isLoading"
            :ripple="false"
            color="secondary"
            small
            class="ma-2"
            @click="download"
          >
            <v-icon left>
              {{ icons.mdiFileExcel }}
            </v-icon>
            ダウンロード
          </v-btn>
        </div>
      </template>

      <template #[`item.attributes.referral.data.attributes.name`]="{item}">
        <v-chip small>
          {{ item.attributes.referral.data.attributes.name }}
        </v-chip>
      </template>

      <template #[`item.attributes.createdAt`]="{item}">
        <span class="text-no-wrap">{{ dateTime(item.attributes.createdAt) }}</span>
      </template>

      <template #[`item.meta.receiptId`]="{item}">
        <v-chip
          v-if="item.meta && item.meta.receiptId"
          small
          @click="$emit('open-receipt', item.meta.receiptId)"
        >
          <clip-loader
            v-if="isLoadingReceipt && +receipt.id === +item.meta.receiptId"
            :loading="true"
            :color="'#8A8D93'"
            size="16px"
            class="d-flex align-items pr-2"
          />
          伝票 No.{{ item.meta.receiptId }}
        </v-chip>
        <v-chip
          v-else
          color="success"
          class="v-chip-light-bg success--text"
          small
        >
          進行中の卓
        </v-chip>
      </template>

      <template #[`item.actions`]="{item}">
        <div class="d-flex align-center justify-end flex-wrap">
          <yona-edit-dialog
            title="削除の確認"
            btn-color="error"
            save-text="同意の上で削除"
            @save="destroy(item.id)"
          >
            <template #append-outer-display-name>
              <v-btn
                icon
                :ripple="false"
              >
                <v-icon small>
                  {{ icons.mdiTrashCanOutline }}
                </v-icon>
              </v-btn>
            </template>

            <template #input>
              <span>
                削除を実行してもよろしいですか（この販売履歴を紐づけたすべての履歴も削除します。）
              </span>
            </template>
          </yona-edit-dialog>
        </div>
      </template>

      <template #[`footer.page-text`]="props">
        {{ props.pageStart }} - {{ props.pageStop }} / {{ props.itemsLength }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {
  ref,
  getCurrentInstance,
  inject,
} from '@vue/composition-api'
import {
  mdiCalendar,
  mdiTrashCanOutline,
  mdiVectorLink,
  mdiFileExcel,
  mdiFilterMenuOutline,
} from '@mdi/js'
import * as XLSX from 'xlsx/xlsx.mjs'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import ReferringHistoryApi from '@/api/waiter/ReferringHistory'
import useDateFormat from '@/views/composable/useDateFormat'
import useQueryString from '@/views/composable/useQueryString'
import useCurrentData from '@/views/composable/useCurrentData'
import useCompInit from '@/views/composable/useCompInit'
import useReferringHistoriesDataTable from '@/views/composable/data-table/useReferringHistoriesDataTable'
import SimpleSelect from '@/views/components/util/filter/SimpleSelect.vue'
import UserSelect from '@/views/components/util/filter/UserSelect.vue'
import YonaEditDialog from '@/views/components/util/YonaEditDialog.vue'
import { ReferringHistoriesHeaders } from '@/utils/constants/table-headers'

export default {
  components: {
    SimpleSelect,
    UserSelect,
    YonaEditDialog,
    ClipLoader,
  },
  props: {
    date: {
      type: String,
      required: true,
      default: () => new Date().toISOString().substr(0, 10),
    },
    interval: {
      type: Number,
      required: true,
      default: 0,
    },
    endDate: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const vm = getCurrentInstance().proxy

    const referringHistories = ref([])
    const referrals = inject('referrals', [])
    const users = inject('users', [])
    const isLoadingReferringHistories = ref(false)
    const receipt = inject('receipt', {})
    const isLoadingReceipt = inject('isLoadingReceipt', false)
    const isReceiptOpen = inject('isReceiptOpen', false)
    const isDownloading = ref(false)
    const isFilterMenuOpen = ref(false)

    const { currentClub } = useCurrentData()
    const { dateTime } = useDateFormat()
    const { route, addQuery } = useQueryString()
    const { isLoading, initWith } = useCompInit()
    const { buildXlsxData } = useReferringHistoriesDataTable(referringHistories)

    const getReferringHistories = async ({
      date = null,
      interval = null,
      referralId = null,
      userId = null,
      tableId = null,
      endDate = null,
    }) => {
      isLoadingReferringHistories.value = true

      const res = await ReferringHistoryApi.getReferringHistories({
        date,
        interval,
        referralId,
        userId,
        tableId,
        endDate,
      })

      if (res?.status === 200) {
        referringHistories.value = res.data.referringHistories.data
      }

      isLoadingReferringHistories.value = false
    }

    const destroy = async id => {
      const res = await ReferringHistoryApi.deleteReferringHistory(id)

      if (res) {
        const index = referringHistories.value.findIndex(o => +o.id === +id)
        referringHistories.value.splice(index, 1)
        vm.$toast.success(`${currentClub.value.referralAlias}履歴を削除しました`)
      }
    }

    const download = () => {
      isDownloading.value = true

      const referringHistoriesExcel = buildXlsxData()

      const wb = XLSX.utils.book_new()
      const data = XLSX.utils.json_to_sheet(referringHistoriesExcel)
      XLSX.utils.book_append_sheet(wb, data, `${currentClub.value?.referralAlias || 'リファラル'}履歴`)

      let title = `${props.date}_${['日', '週', '月'][props.interval]}`
      if (props.endDate) title = `${props.date}~${props.endDate}`
      XLSX.writeFile(wb, `${currentClub.value?.referralAlias || 'リファラル'}履歴_${title}.xlsx`)
      isDownloading.value = false
    }

    initWith([
      getReferringHistories(route.value.query),
    ])

    return {
      // data
      isLoading,
      isLoadingReferringHistories,
      referringHistories,
      ReferringHistoriesHeaders,
      referrals,
      users,
      receipt,
      isLoadingReceipt,
      isReceiptOpen,
      isFilterMenuOpen,

      // methods
      dateTime,
      addQuery,
      getReferringHistories,
      destroy,
      download,

      icons: {
        mdiCalendar,
        mdiTrashCanOutline,
        mdiVectorLink,
        mdiFileExcel,
        mdiFilterMenuOutline,
      },
    }
  },
}
</script>
