var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{ref:"dataTable",attrs:{"loading":_vm.isLoading || _vm.isLoadingTimecards,"headers":_vm.TimecardHeaders,"items":_vm.timecards,"header-props":{ sortByText: 'ソート' },"footer-props":{ itemsPerPageText: '行/ページ:' },"no-data-text":"データがありません","page":_vm.$route.query.page ? Number(_vm.$route.query.page) : 1},on:{"update:page":function ($event) {
      _vm.$vuetify.goTo(_vm.$refs.dataTable)
      _vm.addQuery({ page: $event })
    }},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"table"}})]},proxy:true},{key:"top",fn:function(){return [_c('div',{staticClass:"w-full d-flex align-center"},[_c('v-menu',{attrs:{"close-on-content-click":false,"min-width":"300","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"ml-2",attrs:{"icon":""}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiFilterMenuOutline)+" ")])],1)]}}]),model:{value:(_vm.isFilterMenuOpen),callback:function ($$v) {_vm.isFilterMenuOpen=$$v},expression:"isFilterMenuOpen"}},[_c('v-list',[_c('v-subheader',[_vm._v("ユーザー")]),_c('v-list-item',[_c('v-list-item-content',{staticClass:"px-2"},[_c('user-select',{staticClass:"pa-0 ma-0",attrs:{"users":_vm.users.flatMap(function (user) { return user.attributes.role !== 'alliance' ? user : []; }),"role-order":['cast', 'waiter'],"prepend-inner-icon":_vm.icons.mdiAccountCircleOutline},on:{"selected":function($event){return _vm.getTimecards(Object.assign({}, $event, _vm.$route.query))},"cleared":function($event){return _vm.getTimecards(_vm.$route.query)}}})],1)],1),_c('v-subheader',[_vm._v("ロール")]),_c('v-list-item',[_c('v-list-item-content',{staticClass:"px-2"},[_c('simple-select',{staticClass:"pa-0 ma-0",attrs:{"items":[
                    { id: 'cast', attributes: { name: 'キャスト' } },
                    { id: 'waiter', attributes: { name: 'ボーイ' } } ],"item-id-key-name":"role","prepend-inner-icon":_vm.icons.mdiCardAccountDetailsOutline},on:{"selected":function($event){return _vm.getTimecards(Object.assign({}, $event, _vm.$route.query))},"cleared":function($event){return _vm.getTimecards(_vm.$route.query)}}})],1)],1)],1)],1),_c('v-spacer'),_c('v-btn',{staticClass:"ma-2",attrs:{"loading":_vm.isLoading,"disabled":_vm.timecards.length <= 0 || _vm.isLoading,"ripple":false,"color":"secondary","small":""},on:{"click":_vm.download}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileExcel)+" ")]),_vm._v(" ダウンロード ")],1)],1)]},proxy:true},{key:"item.attributes.startAt",fn:function(ref){
                  var item = ref.item;
return [_c('yona-edit-dialog',{key:("timecard=" + (item.id) + "-startAt-" + (item.attributes.startAt)),attrs:{"display-name":item.attributes.startAt ? _vm.hhmm(item.attributes.startAt) : '未',"is-valid":!_vm.hasErrorInput.timecardStartAt},on:{"open":function($event){_vm.edit({ id: item.id, startAt: item.attributes.startAt ? _vm.dateTimeForJsFriendly(item.attributes.startAt) : _vm.dateTimeForJsFriendly(new Date()) })},"save":_vm.update},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{ref:"timecardStartAtInput",staticClass:"pa-0 ma-0",attrs:{"type":"datetime-local","max":item.attributes.leaveAt ? _vm.dateTimeForJsFriendly(item.attributes.leaveAt) : '',"rules":[
              function () {
                if (!item.attributes.leaveAt) { return true }
                if (_vm.editTarget.startAt && item.attributes.leaveAt) {
                  if (_vm.dateTimeForJsFriendly(_vm.editTarget.startAt.split(' ').join('T')) > _vm.dateTimeForJsFriendly(item.attributes.leaveAt.split(' ').join('T'))) { return '退勤時刻より前の時刻を入力してください' }
                }

                return true
              } ]},model:{value:(_vm.editTarget.startAt),callback:function ($$v) {_vm.$set(_vm.editTarget, "startAt", $$v)},expression:"editTarget.startAt"}})]},proxy:true}],null,true)})]}},{key:"item.attributes.leaveAt",fn:function(ref){
            var item = ref.item;
return [_c('yona-edit-dialog',{key:("timecard=" + (item.id) + "-leaveAt-" + (item.attributes.leaveAt)),attrs:{"display-name":item.attributes.leaveAt ? _vm.hhmm(item.attributes.leaveAt) : '未',"is-valid":!_vm.hasErrorInput.timecardLeaveAt},on:{"open":function($event){_vm.edit({ id: item.id, leaveAt: item.attributes.leaveAt ? _vm.dateTimeForJsFriendly(item.attributes.leaveAt) : _vm.dateTimeForJsFriendly(new Date()) })},"save":_vm.update},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{ref:"timecardLeaveAtInput",staticClass:"pa-0 ma-0",attrs:{"type":"datetime-local","min":_vm.dateTimeForJsFriendly(item.attributes.startAt),"rules":[
              function () {
                if (_vm.editTarget.leaveAt && item.attributes.startAt) {
                  if (_vm.dateTimeForJsFriendly(_vm.editTarget.leaveAt.split(' ').join('T')) < _vm.dateTimeForJsFriendly(item.attributes.startAt.split(' ').join('T'))) { return '出勤時刻より後の時刻を入力してください' }
                }

                return true
              } ]},model:{value:(_vm.editTarget.leaveAt),callback:function ($$v) {_vm.$set(_vm.editTarget, "leaveAt", $$v)},expression:"editTarget.leaveAt"}})]},proxy:true}],null,true)})]}},{key:"item.attributes.breakCards.data",fn:function(ref){
            var item = ref.item;
return [_c('yona-edit-dialog',{key:("timecard=" + (item.id) + "-breakTimecards"),attrs:{"is-valid":!_vm.hasErrorInput.breakTimecardStartAt && !_vm.hasErrorInput.breakTimecardEndAt},on:{"save":_vm.update,"close":_vm.cancelEdit},scopedSlots:_vm._u([{key:"append-outer-display-name",fn:function(){return [(item.attributes.breakTimecards.data && item.attributes.breakTimecards.data.length > 0)?_c('v-chip-group',{key:("timecard=" + (item.id) + "-breakTimecards-" + (JSON.stringify(item.attributes.breakTimecards.data))),attrs:{"column":"","small-chips":""}},_vm._l((item.attributes.breakTimecards.data),function(breakTimecard){return _c('v-chip',{key:("breakTimecard" + (breakTimecard.id) + "-" + (breakTimecard.attributes.startAt) + "-" + (breakTimecard.attributes.endAt)),attrs:{"small":""},on:{"click":function($event){_vm.edit({
                id: item.id,
                breakTimecardsAttributes: [{
                  id: breakTimecard.id,
                  breakTimeStartAt: _vm.dateTimeForJsFriendly(breakTimecard.attributes.breakTimeStartAt ? breakTimecard.attributes.breakTimeStartAt : item.attributes.startAt),
                  breakTimeEndAt: _vm.dateTimeForJsFriendly(breakTimecard.attributes.breakTimeEndAt ? breakTimecard.attributes.breakTimeEndAt : item.attributes.startAt),
                }]
              })}}},[(breakTimecard.attributes.breakTimeStartAt)?_c('span',[_vm._v(" "+_vm._s(_vm.hhmm(breakTimecard.attributes.breakTimeStartAt))+" ")]):_vm._e(),_c('span',{staticClass:"mx-3"},[_vm._v("~")]),(breakTimecard.attributes.breakTimeEndAt)?_c('span',[_vm._v(" "+_vm._s(_vm.hhmm(breakTimecard.attributes.breakTimeEndAt))+" ")]):_vm._e()])}),1):_vm._e()]},proxy:true},{key:"input",fn:function(){return [_c('div',[(_vm.isEditingHere(item.id, 'breakTimecardsAttributes[0]'))?_c('span',[_c('v-text-field',{ref:"breakTimecardStartAtInput",staticClass:"pa-0 ma-0 my-8",attrs:{"type":"datetime-local","label":"IN","min":_vm.dateTimeForJsFriendly(item.attributes.startAt),"rules":[
                  function () {
                    if (_vm.dateTimeForJsFriendly(_vm.editTarget.breakTimecardsAttributes[0].breakTimeStartAt.split(' ').join('T')) < _vm.dateTimeForJsFriendly(item.attributes.startAt.split(' ').join('T'))) {
                      return '出勤時刻より後の時刻を入力してください'
                    }

                    return true
                  } ]},model:{value:(_vm.editTarget.breakTimecardsAttributes[0].breakTimeStartAt),callback:function ($$v) {_vm.$set(_vm.editTarget.breakTimecardsAttributes[0], "breakTimeStartAt", $$v)},expression:"editTarget.breakTimecardsAttributes[0].breakTimeStartAt"}}),_c('v-text-field',{ref:"breakTimecardEndAtInput",staticClass:"pa-0 ma-0",attrs:{"type":"datetime-local","label":"OUT","min":_vm.dateTimeForJsFriendly(_vm.editTarget.breakTimecardsAttributes[0].breakTimeStartAt),"rules":[
                  function () {
                    if (_vm.dateTimeForJsFriendly(_vm.editTarget.breakTimecardsAttributes[0].breakTimeEndAt.split(' ').join('T')) < _vm.dateTimeForJsFriendly(_vm.editTarget.breakTimecardsAttributes[0].breakTimeStartAt.split(' ').join('T'))) {
                      return '休憩入りより後の時刻を入力してください'
                    }

                    return true
                  } ]},model:{value:(_vm.editTarget.breakTimecardsAttributes[0].breakTimeEndAt),callback:function ($$v) {_vm.$set(_vm.editTarget.breakTimecardsAttributes[0], "breakTimeEndAt", $$v)},expression:"editTarget.breakTimecardsAttributes[0].breakTimeEndAt"}})],1):_vm._e()])]},proxy:true}],null,true)})]}},{key:"break_timecard.attributes.breakTimeEndAt",fn:function(ref){
                var item = ref.item;
return [(item)?_c('span'):_vm._e()]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-end flex-wrap"},[_c('yona-edit-dialog',{attrs:{"title":"削除の確認","btn-color":"error","save-text":"同意の上で削除"},on:{"save":function($event){return _vm.destroy(item.id)}},scopedSlots:_vm._u([{key:"append-outer-display-name",fn:function(){return [_c('v-btn',{attrs:{"icon":"","ripple":false}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCanOutline)+" ")])],1)]},proxy:true},{key:"input",fn:function(){return [_c('span',[_vm._v(" 削除を実行してもよろしいですか（このタイムカードを紐づけたすべての履歴も削除します。） ")])]},proxy:true}],null,true)})],1)]}},{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+" - "+_vm._s(props.pageStop)+" / "+_vm._s(props.itemsLength)+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }