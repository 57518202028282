var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{ref:"dataTable",attrs:{"loading":_vm.isLoading || _vm.isLoadingReferringHistories,"headers":_vm.ReferringHistoriesHeaders,"items":_vm.referringHistories,"header-props":{ sortByText: 'ソート' },"footer-props":{ itemsPerPageText: '行/ページ:' },"no-data-text":"データがありません","page":_vm.$route.query.page ? Number(_vm.$route.query.page) : 1},on:{"update:page":function ($event) {
      _vm.$vuetify.goTo(_vm.$refs.dataTable)
      _vm.addQuery({ page: $event })
    }},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"table"}})]},proxy:true},{key:"top",fn:function(){return [_c('div',{staticClass:"w-full d-flex align-center"},[_c('v-menu',{attrs:{"close-on-content-click":false,"min-width":"300","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"ml-2",attrs:{"icon":""}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiFilterMenuOutline)+" ")])],1)]}}]),model:{value:(_vm.isFilterMenuOpen),callback:function ($$v) {_vm.isFilterMenuOpen=$$v},expression:"isFilterMenuOpen"}},[_c('v-list',[_c('v-subheader',[_vm._v("種別")]),_c('v-list-item',[_c('v-list-item-content',{staticClass:"px-2"},[_c('simple-select',{staticClass:"pa-0 ma-0",attrs:{"items":_vm.referrals,"item-id-key-name":'referralId',"prepend-inner-icon":_vm.icons.mdiVectorLink},on:{"selected":function($event){return _vm.getReferringHistories(Object.assign({}, $event, _vm.$route.query))},"cleared":function($event){return _vm.getReferringHistories(_vm.$route.query)}}})],1)],1),_c('v-subheader',[_vm._v("ユーザー")]),_c('v-list-item',[_c('v-list-item-content',{staticClass:"px-2"},[_c('user-select',{staticClass:"pa-0 ma-0",attrs:{"users":_vm.users,"role-order":['alliance', 'cast', 'waiter']},on:{"selected":function($event){return _vm.getReferringHistories(Object.assign({}, $event, _vm.$route.query))},"cleared":function($event){return _vm.getReferringHistories(_vm.$route.query)}}})],1)],1),_c('v-subheader')],1)],1),_c('v-spacer'),_c('v-btn',{staticClass:"ma-2",attrs:{"loading":_vm.isLoading,"disabled":_vm.referringHistories.length <= 0 || _vm.isLoading,"ripple":false,"color":"secondary","small":""},on:{"click":_vm.download}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileExcel)+" ")]),_vm._v(" ダウンロード ")],1)],1)]},proxy:true},{key:"item.attributes.referral.data.attributes.name",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_vm._v(" "+_vm._s(item.attributes.referral.data.attributes.name)+" ")])]}},{key:"item.attributes.createdAt",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.dateTime(item.attributes.createdAt)))])]}},{key:"item.meta.receiptId",fn:function(ref){
    var item = ref.item;
return [(item.meta && item.meta.receiptId)?_c('v-chip',{attrs:{"small":""},on:{"click":function($event){return _vm.$emit('open-receipt', item.meta.receiptId)}}},[(_vm.isLoadingReceipt && +_vm.receipt.id === +item.meta.receiptId)?_c('clip-loader',{staticClass:"d-flex align-items pr-2",attrs:{"loading":true,"color":'#8A8D93',"size":"16px"}}):_vm._e(),_vm._v(" 伝票 No."+_vm._s(item.meta.receiptId)+" ")],1):_c('v-chip',{staticClass:"v-chip-light-bg success--text",attrs:{"color":"success","small":""}},[_vm._v(" 進行中の卓 ")])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-end flex-wrap"},[_c('yona-edit-dialog',{attrs:{"title":"削除の確認","btn-color":"error","save-text":"同意の上で削除"},on:{"save":function($event){return _vm.destroy(item.id)}},scopedSlots:_vm._u([{key:"append-outer-display-name",fn:function(){return [_c('v-btn',{attrs:{"icon":"","ripple":false}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCanOutline)+" ")])],1)]},proxy:true},{key:"input",fn:function(){return [_c('span',[_vm._v(" 削除を実行してもよろしいですか（この販売履歴を紐づけたすべての履歴も削除します。） ")])]},proxy:true}],null,true)})],1)]}},{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+" - "+_vm._s(props.pageStop)+" / "+_vm._s(props.itemsLength)+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }