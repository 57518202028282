import { serialize } from 'object-to-formdata'
import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getBalanceReceivables({
    date = null,
    interval = null,
    isPaid = false,
    endDate = null,
  }) {
    return axiosIns.get(
      '/waiter/balance_receivables',
      {
        params: snakecaseKeys({
          date,
          interval,
          isPaid,
          endDate,
        }),
      },
    )
  },
  updateBalanceReceivable({
    id,
    paidAmount,
    note,
    cashRegisterTransactionsAttributes,
  }) {
    const formData = serialize(snakecaseKeys({
      balanceReceivable: {
        id,
        paidAmount,
        note,
        cashRegisterTransactionsAttributes,
      },
    }))

    return axiosIns.put(`/waiter/balance_receivables/${id}`, formData)
  },
}
